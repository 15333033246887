import React, { useRef, useState, useEffect } from "react"
import { graphql } from "gatsby"
import blogTemplateStyles from "./blogTemplate.module.scss"
import SEO from "../components/seo"
import { Link } from "gatsby"
import Thumb from "../images/thumb.png"


export const query = graphql`
  query($slug: String!) {
    wordpressPost(slug: { eq: $slug }) {
      slug
      id
      title
      date(formatString: "MMMM DD, YYYY", locale: "es")
      author {
        id
        name
        slug
        specializedin
      }
      content
    }
    allWordpressPost(limit: 10, filter: { slug: { ne: $slug } }) {
      edges {
        node {
          slug
          title
          date(formatString: "DD MMMM YYYY", locale: "es")
          featured_media {
            title
            source_url
          }
        }
      }
    }
  }
`

const Blog = props => {
  const rawDate = props.data.wordpressPost.date
  const date = rawDate.charAt(0).toUpperCase() + rawDate.slice(1)
  const words = props.data.wordpressPost.content.split(" ").length
  const readingTime = Math.round(words / 200)
  const previousBlog = props.pageContext.previous
  const nextBlog = props.pageContext.next

  const carouselRef = useRef(null)
  const x = carouselRef.current
    ? carouselRef.current.scrollWidth / props.data.allWordpressPost.edges.length
    : ""

  function moveLeft() {
    carouselRef.current.scrollLeft -= x
  }
  function moveRight() {
    carouselRef.current.scrollLeft += x
  }

  const [subscriptionSubmitted, setSubscriptionSubmitted] = useState(false)
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")

  const [submitted, setSubmitted] = useState(false)
  const [votes, setVotes] = useState({ up: 0, down: 0 })

  function handleVote(e, x) {
    e.preventDefault()
    if (submitted) {
      alert("Ya has votado en esta publicación!")
      return
    }
    const fetchData = {
      campaingID: props.data.wordpressPost.id,
      upOrDown: x === 1 ? "true" : "false",
      author: props.data.wordpressPost.author.id,
      title: props.data.wordpressPost.title,
    }
    fetch("https://unio-mx.herokuapp.com/blogData/setPostData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(fetchData),
    }).then(response => {
      if (response.ok) {
        setSubmitted(true)
      } else {
        setSubmitted(false)
      }
    })
  }
  useEffect(() => {
    const getData = {
      campaingID: props.data.wordpressPost.id,
    }
    function fetchData() {
      fetch("https://unio-mx.herokuapp.com/blogData/getPostData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(getData),
      }).then(r => {
        if (r.ok) {
          r.json().then(function changeVotes(d) {
            if (!d.message) {
              setVotes({
                up: d.Up,
                down: d.Down,
              })
            } else
              setVotes({
                up: 0,
                down: 0,
              })
          })
        } else setVotes("error")
      })
    }

    fetchData()
  }, [submitted, props.data.wordpressPost.id])

  function handleCommentSubmit(e) {
    e.preventDefault()
    if (subscriptionSubmitted) {
      alert("Te has suscrito!")
      return
    }
    if (name.length < 3 || email.length < 8) {
      alert("Pon tu informción completa!")
      return
    }
    const subscriptionData = {
      name,
      email,
    }
    fetch("https://hook.integromat.com/cjgd821ptcxuy4l778m2zpdmxf34mhky", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(subscriptionData),
    }).then(response => {
      if (response.ok) {
        setSubscriptionSubmitted(true)
        alert("Se ha enviado tu comentario")
      } else {
        setSubscriptionSubmitted(false)
      }
    })
  }

  return (
    <>
      <div className={blogTemplateStyles.generalContainer}>
        <SEO
          title={props.data.wordpressPost.title}
          url={`/blog/${props.data.wordpressPost.slug}`}
          description={props.data.wordpressPost.title}
        />
        <div className={blogTemplateStyles.container}>
          <h1 className={blogTemplateStyles.title}>
            {props.data.wordpressPost.title}
          </h1>
          <div className={blogTemplateStyles.voteContainer}>
            ¿Te fue útil este artículo?
            <div className={blogTemplateStyles.votingContainer}>
              <button
                className={blogTemplateStyles.voteButton}
                value={1}
                onClick={(e, x = 1) => handleVote(e, x)}
              >
                <img
                  alt="Votar a Favor"
                  src={Thumb}
                  value={1}
                  style={{ height: ".8em" }}
                />{" "}
                {votes.up}
              </button>
              <button
                className={blogTemplateStyles.voteButton}
                value={0}
                onClick={(e, x = 0) => handleVote(e, x)}
              >
                <img
                  alt="Votar en contra"
                  src={Thumb}
                  value={0}
                  style={{ height: ".8em", transform: "rotatex(190deg)" }}
                />{" "}
                {votes.down}
              </button>
            </div>
          </div>
          <div>
            <p className={blogTemplateStyles.date}>
              {date} | Tiempo estimado de lectura: {readingTime} min.
            </p>
          </div>
          <Link
            className={blogTemplateStyles.authorLink}
            title={props.data.wordpressPost.author.name}
            to={`/specialists/${props.data.wordpressPost.author.slug}`}
          >
            <p className={blogTemplateStyles.authorLinkName}>
              Por: {props.data.wordpressPost.author.name}
            </p>
            <p className={blogTemplateStyles.authorLinkSpecializedin}>
              {props.data.wordpressPost.author.specializedin}
            </p>
          </Link>
          <div
            dangerouslySetInnerHTML={{
              __html: props.data.wordpressPost.content,
            }}
          ></div>
          <div className={blogTemplateStyles.linksContainer}>
            {previousBlog ? (
              <Link
                title="home"
                className={blogTemplateStyles.link}
                to={`blog/${previousBlog}`}
              >
                {"<"}Anterior
              </Link>
            ) : (
              ""
            )}
            {nextBlog ? (
              <Link
                title="home"
                className={blogTemplateStyles.link}
                to={`blog/${nextBlog}`}
              >
                Siguiente{">"}
              </Link>
            ) : (
              ""
            )}
          </div>
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <a
              href="http://uniomx.com/?utm_source=unionunio.com"
              title="Registro"
              rel="noreferrer noopener"
              className={blogTemplateStyles.linkItem}
            >
              Descubre Unio
            </a>
          </div>
        </div>
        <form
          className={blogTemplateStyles.form}
          onSubmit={e => handleCommentSubmit(e)}
        >
          <legend className={blogTemplateStyles.formTitle}>
            Suscríbete a Union by Unio
          </legend>
          <label htmlFor="name" className={blogTemplateStyles.label}>
            Nombre
          </label>
          <input
            className={blogTemplateStyles.input}
            onChange={e => setName(e.target.value)}
            placeholder="Juan Perez"
            value={name}
            name="name"
            autoComplete="name"
            required
          />
          <label htmlFor="email" className={blogTemplateStyles.label}>
            Email
          </label>
          <input
            className={blogTemplateStyles.input}
            onChange={e => setEmail(e.target.value)}
            placeholder="juan@correo.com"
            value={email}
            required
            autoComplete="email"
          />
          <button type="submit" className={blogTemplateStyles.button}>
            Suscribirse
          </button>
        </form>
        <div className={blogTemplateStyles.sectionContainer}>
          <h2 className={blogTemplateStyles.title}>Comentarios</h2>
          <div
            className="fb-comments"
            data-href={`https://unionunio.com/blog/${props.data.wordpressPost.slug}/`}
            data-width="800px"
            data-numposts="5"
          ></div>
        </div>
        <div className={blogTemplateStyles.sectionContainer}>
          <h2 className={blogTemplateStyles.title}>
            También te pueden interesar estos artículos
          </h2>
        </div>
        <ol className={blogTemplateStyles.posts} ref={carouselRef}>
          {props.data.allWordpressPost.edges.map(post => {
            const image = post.node.featured_media
              ? post.node.featured_media.source_url
              : "https://www.uniomx.com/static/ni%C3%B1os-4db7bcedfa4f813de3752dcf15e04716.png"
            return (
              <>
                <div className={blogTemplateStyles.gridItem}>
                  <Link
                    to={`/blog/${post.node.slug}`}
                    title={post.title}
                    className={blogTemplateStyles.blogpost}
                  >
                    <img
                      className={blogTemplateStyles.blogImage}
                      src={image}
                      alt={post.node.title}
                    />
                    <h3 className={blogTemplateStyles.blogTitle}>
                      {post.node.title.length > 100
                        ? post.node.title.slice(0, 50) + "..."
                        : post.node.title}
                    </h3>
                    <h3 className={blogTemplateStyles.blogDate}>
                      {post.node.date}
                    </h3>
                  </Link>
                </div>
              </>
            )
          })}
        </ol>
        <div className={blogTemplateStyles.buttonContainer}>
          <button
            onClick={moveLeft}
            className={blogTemplateStyles.carouselButton}
          >
            {"<"}
          </button>
          <button
            onClick={moveRight}
            className={blogTemplateStyles.carouselButton}
          >
            {">"}
          </button>
        </div>
      </div>
    </>
  )
}

export default Blog
